import { IUser, IUserFromDB, IUserFromWhoAmI } from '../../../../interfaces/IUser';
import { IFieldMapped } from '../../../../../../app-card/src/interfaces/IUser';

export const mapUserFromDbToFe = (user: IUserFromDB): IUser => {
    const mapped: IFieldMapped = {};

    user.fields?.forEach((item) => {
        // @ts-ignore
        mapped[item.name] = item.value ?? '';
    });

    return { ...user, fields: mapped };
};

export const mapUserFromWhoAmIToFe = (user: IUserFromWhoAmI): IUser => {
    const mapped: IFieldMapped = {};

    user.fields?.forEach((item) => {
        // @ts-ignore
        mapped[item.fieldName] = item.fieldValue ?? '';
    });

    return { ...user, fields: mapped };
};
