import { useSetRecoilState } from 'recoil';
import { apiState } from '../../recoil/request';
import { useCallback } from 'react';
import { refresh, ubootRefreshHref } from '../../lib/refresh';
import { useEvent } from 'react-use';
import { getRedirectUrl } from '../../components/Auth/authUtil';
import { isJSON } from '../../helper/isJson';
import { usePathname } from 'next/navigation';

export const useApiRefresh = () => {
    const setAuthState = useSetRecoilState(apiState);

    const pathname = usePathname();

    //ServiceApi token refreshen und dem AuthGuard die neue refreshSrc überreichen
    const refreshCB = useCallback(async () => {
        if (!pathname?.startsWith('/login')) {
            setAuthState((context) => {
                return { ...context, isRefreshing: true };
            });

            return refresh()
                .then((refreshResult) => {
                    setAuthState((context) => {
                        return {
                            ...context,
                            refreshSrc: ubootRefreshHref(refreshResult),
                        };
                    });
                    return true;
                })
                .catch(async () => {
                    window.location.href = getRedirectUrl();
                    return false;
                });
        }
        return false;
    }, [pathname, setAuthState]);

    //Die nachricht vom AuthGuard iFrame abfangen auf refreshed um refreshen zu clearen
    useEvent('message', (event: MessageEvent) => {
        if (event.data && isJSON(event.data as string)) {
            const data = JSON.parse(event.data as string) as {
                command: 'refreshed' | 'notRefreshed';
            };

            if (data.command === 'refreshed') {
                setAuthState((currVal) => {
                    return { ...currVal, isRefreshing: false };
                });
            }
        }
    });

    return { refresh: refreshCB };
};
