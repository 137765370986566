import { atom } from 'recoil';
import { nanoid } from 'nanoid';

type TTabs = 'direct' | 'assistent' | 'iconnect' | 'at';

interface ILoginAtom {
    currentTab: TTabs;
    language: string;
    locale: string;
}

export const loginStateAtom = atom<ILoginAtom>({
    key: `login-state/${nanoid()}`,
    default: {
        currentTab: 'direct',
        language:
            typeof localStorage !== 'undefined' && localStorage['localLanguage']
                ? localStorage['localLanguage']
                : 'en',
        locale:
            typeof localStorage !== 'undefined' && localStorage['localLocale']
                ? localStorage['localLocale']
                : 'en-GB',
    },
});
