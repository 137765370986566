import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useApi } from '../../../useApi';
import { IUser, IUserFromDB } from '../../../../../interfaces/IUser';
import { mapUserFromDbToFe } from '../mapUserFromDbToFe';

export const useUser = (enabled = true) => {
    const { postGql } = useApi();

    return useQuery<IUser>(
        ['user'],
        async () => {
            const { user } = await postGql<{ user: IUserFromDB }>(
                gql`
                    query {
                        user {
                            id
                            userName
                            email
                            language
                            thumbnail
                            logonName
                            exemptFromVat
                            fields {
                                name
                                value
                            }
                            groups
                        }
                    }
                `,
                undefined,
                'user'
            );

            return mapUserFromDbToFe(user);
        },
        { staleTime: 60000, enabled }
    );
};
