import { apiPost } from './api';

interface TRefreshResponse {
    ticket: string;
    logonName: string;
    userID: string;
}

export const refresh = async (): Promise<TRefreshResponse> => {
    return apiPost<TRefreshResponse, never>('/auth/refresh');
};

export const ubootRefreshHref = ({ ticket, logonName, userID }: TRefreshResponse): string => {
    return `${
        process.env['NEXT_PUBLIC_SHOP_URL'] ?? ''
    }UBootLogin.php?ticket=${ticket}&logonName=${logonName}&userID=${userID}&refresh=1`;
};
