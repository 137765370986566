import { useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import { useLatest } from 'react-use';
import axios, { AxiosError } from 'axios';
import { useApiRefresh } from './useApiRefresh';
import { apiState } from '../../recoil/request';
import { IGQLRequest, IGQLReturn } from '../../interfaces/gql/request';
import { getRedirectUrl } from '../../components/Auth/authUtil';
import { apiGet, apiPost } from '../../lib/api';
import { usePathname } from 'next/navigation';

export const useApi = () => {
    const { isRefreshing } = useRecoilValue(apiState);
    const isRefreshingLatest = useLatest<boolean>(isRefreshing);
    const { refresh } = useApiRefresh();

    const pathname = usePathname();

    const get = useCallback(
        async <T>(path: string, doRefresh = true) => {
            const result = await apiGet<T>(path).catch(async (e: AxiosError) => {
                if (doRefresh && !isRefreshingLatest.current) {
                    if (await refresh()) {
                        return apiGet<T>(path);
                    }
                }
                return e;
            });
            if (result) {
                if (axios.isAxiosError(result)) {
                    throw result;
                }
                return result;
            } else {
                if (!pathname?.startsWith('/login')) {
                    window.location.href = getRedirectUrl();
                }
                return false;
            }
        },
        [isRefreshingLatest, pathname, refresh]
    );

    const post = useCallback(
        async <T, G>(path: string, data?: G) => {
            const result = await apiPost<T, G>(path, data).catch(async (e: AxiosError) => {
                if (!isRefreshingLatest.current) {
                    if (await refresh()) {
                        return apiPost<T, G>(path, data);
                    }
                }
                return e;
            });

            if (result) {
                if (axios.isAxiosError(result)) {
                    throw result;
                }
                return result;
            } else {
                if (!pathname?.startsWith('/login')) {
                    window.location.href = getRedirectUrl();
                }
                return false;
            }
        },
        [isRefreshingLatest, pathname, refresh]
    );

    const postGql = useCallback(
        async <T>(query: string, variables?: { [key: string]: unknown }, name?: string) => {
            const result = await apiPost<IGQLReturn<T>, IGQLRequest>(
                `/graphql${query.includes('mutation') ? `?m=${name ?? ''}` : `?q=${name ?? ''}`}`,
                {
                    query,
                    variables,
                }
            );

            if (result) {
                if (result.errors) {
                    switch (result.errors[0]?.message) {
                        case 'Not Found':
                            throw new Error('404');
                        case 'Unauthorized':
                            if (!isRefreshingLatest.current) {
                                await refresh();
                            }
                            throw new Error();
                        default:
                            throw new Error(result.errors[0]?.message);
                    }
                }
                return result.data;
            } else {
                throw new Error();
            }
        },
        [isRefreshingLatest, refresh]
    );

    const postUpload = useCallback(
        async <Type>(
            query: string,
            variables: { [key: string]: unknown },
            map: { [key: string]: string[] },
            files: File[]
        ): Promise<Type> => {
            const formData = new FormData();
            formData.append('operations', JSON.stringify({ query, variables }));
            formData.append('map', JSON.stringify(map));

            files.forEach((file, index) => {
                formData.append(String(index), file);
            });

            const result = await apiPost<IGQLReturn<Type>, FormData>('/graphql', formData);
            if (result) {
                if (result.errors) {
                    switch (result.errors[0]?.message) {
                        case 'Not Found':
                            throw new Error('404');
                        case 'Unauthorized':
                            if (!isRefreshingLatest.current) {
                                await refresh();
                            }
                            throw new Error();
                        default:
                            throw new Error(result.errors[0]?.message);
                    }
                }
                return result.data;
            } else {
                throw new Error();
            }
        },
        [isRefreshingLatest, refresh]
    );

    return { get, post, postGql, postUpload };
};
