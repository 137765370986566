import axios from 'axios';

export const apiPost = async <T, G>(path: string, data?: G): Promise<T> => {
    return axios
        .post<T>(`${String(process.env['NEXT_PUBLIC_SERVICE_SHOP_URL'])}${path}`, data, {
            withCredentials: true,
        })
        .then((res) => res.data);
};

export const apiGet = async <T>(path: string): Promise<T> => {
    return axios
        .get<T>(`${String(process.env['NEXT_PUBLIC_SERVICE_SHOP_URL'])}${path}`, {
            withCredentials: true,
        })
        .then((res) => res.data);
};
