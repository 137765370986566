export const getRedirectUrl = (): string => {
    const path = window.location.pathname + window.location.search;
    if (path.includes('redirect=')) {
        return path;
    }
    return path !== '/'
        ? `/shop/logout?redirect=${encodeURIComponent(
              Buffer.from(path.replace('/shop', '')).toString('base64')
          )}`
        : '/shop/logout';
};
