import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { loginStateAtom } from '../../../components/LoginArea/recoil/loginAtom';
import { useUser } from '../gql/user/queries/useUser';
import { usePathname } from 'next/navigation';
import { useIsStorybook } from '../../isStorybook';

export const useUserLanguage = () => {
    const pathname = usePathname();
    const { isStorybook } = useIsStorybook();

    const { data: user } = useUser(
        !pathname?.startsWith('/login') &&
            !pathname?.startsWith('/register') &&
            !pathname?.startsWith('/forgotpw') &&
            !isStorybook
    );
    const { locale } = useRecoilValue(loginStateAtom);

    return useMemo<string | undefined>(() => {
        if (user) {
            return user.language;
        }
        if (locale) {
            return locale;
        }
        return undefined;
    }, [locale, user]);
};
