import { atom } from 'recoil';
import { nanoid } from 'nanoid';

export interface IRequestAtom {
    isRefreshing: boolean;
    refreshSrc: string;
}

export const apiState = atom<IRequestAtom>({
    key: `requestState/${nanoid()}`,
    default: {
        isRefreshing: false,
        refreshSrc: '',
    },
});
